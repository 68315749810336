<template>
    <div>
        <b-card no-body class="mb-2 p-1">
            <b-row>
                <b-col md="12" class="text-right">
                    <b-button variant="success" @click="exportCsv" class="mr-1">Экспорт</b-button>
                    <b-button variant="primary" @click="load" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                </b-col>
            </b-row>
            <b-collapse id="filters" visible>
                <div class="card-body">
                    <b-row>
                        <b-col md="3">
                            <b-form-group description="Дата от">
                                <input type="date" class="form-control" v-model="filters.date_from"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Дата до">
                                <input type="date" class="form-control" v-model="filters.date_to"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Подразделение">
                                <department-select v-model="filters.departments" multiple/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Смена">
                                <shift-input v-model="filters.shift"></shift-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3">
                            <b-form-group description="Провел проверку">
                                <user-input v-model="filters.technologist"></user-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Отобразить только среднее:">
                                <b-checkbox v-model="filters.show_only_average"></b-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3">
                            <b-form-group description="Показать всех кто работал с:">
                                <b-checkbox v-model="filters.show_everyone_who_worked_with"></b-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="ФИО пользователя">
                                <user-input :disabled="!filters.show_everyone_who_worked_with"
                                            v-model="filters.user"></user-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Должность">
                                <work-position-select v-model="filters.work_position"></work-position-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </b-card>

        <b-table
            responsive
            head-variant="dark"
            :items="items"
            :fields="fields"
            hover
        >

        </b-table>
    </div>
</template>

<script>
    import moment from 'moment';
    import CSV from "@utils/csv";

    import UserInput from "@components/_common/UserInput";
    import ShiftInput from "@components/_common/ShiftInput";
    import {SANITARY_INSPECTION_REPORT} from "@utils/endpoints";
    import {DepartmentSelect, WorkPositionSelect} from '@components';

    export default {
        name: 'Index',
        components: {
            UserInput,
            WorkPositionSelect,
            ShiftInput,
            DepartmentSelect,
        },
        data() {
            return {
                filters: {
                    date_from: moment().format('YYYY-MM-DD'),
                    date_to: moment().format('YYYY-MM-DD'),
                    department: null,
                    departments: null,
                    shift: null,
                    technologist: null,
                    user: null,
                    user_position: null,
                    show_everyone_who_worked_with: false,
                    show_only_average: false,
                },
                fields: [
                    {
                        key: "date",
                        label: "Дата",
                        formatter: function (value) {
                            if (!value) {
                                return;
                            }

                            return moment(value).format('DD.MM.YYYY')
                        }
                    },
                    {key: "user_fullname", label: 'ФИО'},
                    {key: 'work_position', label: 'Должность'},
                    {key: "department", label: "Поздразделение"},
                    {key: "actual_shift_name", label: "Смена"},
                    {key: "score", label: "Балл 1 проверки"},
                    {key: "additional_score", label: "Балл 2 проверки"},
                    {key: "created_by", label: "Провел проверку"},
                    {key: 'count', label: 'Количество проверок'},
                ],
                items: [],
            }
        },
        computed: {
            clearedFilters() {
                return {
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                    department_id: this.filters.department && this.filters.department.id || null,
                    department_ids: this.filters.departments?.map((department) => department.id),
                    shift_id: this.filters.shift && this.filters.shift.id || null,
                    technologist_id: this.filters.technologist && this.filters.technologist.id,
                    user_id: this.filters.user && this.filters.user.id || null,
                    show_only_average: this.filters.show_only_average,
                    show_everyone_who_worked_with: this.filters.show_everyone_who_worked_with,
                    without_loading: false,
                    work_position_id: this.filters.work_position && this.filters.work_position.id || null,
                }
            },
        },
        methods: {
            load() {
                this.$http.post(SANITARY_INSPECTION_REPORT, this.clearedFilters).then((response) => {
                    this.items = response.data.data.data
                })
            },
            exportCsv() {
                this.$http.post(SANITARY_INSPECTION_REPORT + '/csv', this.clearedFilters)
                    .then(response => {
                        CSV.download(response.data, "Проверка санитарных норм")
                    })
                    .catch(response => {
                        ErrorsBag.fill(response.body)
                        this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                        window.scrollTo({top: 0, behavior: 'smooth'})
                    })

            },
        }
    }
</script>
